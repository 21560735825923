import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;


const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;

// Modifier la largeur maximale et la hauteur des cartes
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 w-full h-full`; 

// Définir une hauteur fixe pour les images
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}

  /* Ajouter une règle pour que les images s'adaptent bien dans le conteneur */
  img {
    object-fit: cover; /* Empêche l'étirement de l'image */
    height: 100%;      /* L'image occupe toute la hauteur du conteneur */
    width: 100%;       /* L'image occupe toute la largeur du conteneur */
  }
`;

const CardText = tw.div`p-4 text-gray-900 flex-grow`; // Permet à CardText de s'étendre pour remplir l'espace



const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Checkout the Menu",
  tabs = {
    "Langage Naturel": [
      {
        imageSrc:
          "https://freeappsai.com/wp-content/uploads/2024/06/Siri.jpg?&auto=format&fit=crop&w=500&q=80",
        title: "Siri (Apple)",
        content: "Utilise le traitement du langage naturel pour comprendre et répondre aux commandes vocales des utilisateurs.",
        url: "https://www.apple.com/fr/siri/"
      },
      {
        imageSrc:
          "https://cdn.kulturegeek.fr/wp-content/uploads/2024/01/Google-Assistant-Logo.jpg?auto=format&fit=crop&w=500&q=80",
        title: "Google Assistant",
        content: "Assure des interactions naturelles entre les utilisateurs et les appareils Google, permettant la recherche, les commandes d'appareils et plus encore.",
        url: "https://assistant.google.com/intl/fr_fr/"
      },
      {
        imageSrc:
          "https://bizweb.dktcdn.net/100/497/222/articles/openai.png?v=1696816855197&auto=format&fit=crop&w=500&q=80",
        title: "GPT-4 (OpenAI)",
        content: "Un modèle de langage avancé qui permet des conversations naturelles, des réponses complexes, et même la génération de texte créatif.",
        url: "https://openai.com/chatgpt/"
      },
      {
        imageSrc:
          "https://logo-marque.com/wp-content/uploads/2021/03/Duolingo-Logo-2011-2012.jpg?auto=format&fit=crop&w=500&q=80",
        title: "Duolingo ",
        content: "Utilise le NLP pour proposer des leçons interactives de langues, s'adaptant au niveau et aux réponses des utilisateurs.",
        url: "https://fr.duolingo.com"
      },

    ],
    "Vision": [
      {
        imageSrc:
          "https://cdn.wallpapersafari.com/60/25/jAG3CS.jpg?fit=crop&w=500&q=80",
        title: "Tesla Autopilot",
        content: "Utilise la vision par ordinateur pour la conduite autonome, permettant aux voitures de détecter des obstacles, des routes et des panneaux de signalisation.",
        url: "https://www.tesla.com/fr_fr/AI"
      },
      {
        imageSrc:
          "https://img.phonandroid.com/2020/05/google-photos-nouvelle-interface.jpg?fit=crop&w=500&q=80",
        title: "Google Photos",
        content: "Permet la recherche d'images en reconnaissant des objets, des lieux, et des personnes dans les photos.",
        url: "https://cloud.google.com/vision?hl=fr"
      },
      {
        imageSrc:
          "https://t3.ftcdn.net/jpg/02/94/22/52/360_F_294225253_YiGjFQNJfEHvd8jpGAZJJSNGSfBnZxMy.jpg?fit=crop&w=500&q=80",
        title: "Face ID (Apple)",
        content: "Utilise la reconnaissance faciale pour permettre aux utilisateurs de déverrouiller leurs iPhones de manière sécurisée.",
        url: "https://support.apple.com/fr-fr/102381"
      },
      {
        imageSrc:
          "https://viso.ai/wp-content/uploads/2022/04/opencv-wallpaper.png?fit=crop&w=500&q=80",
        title: "OpenCV",
        content: "Une bibliothèque open-source très utilisée pour des applications de vision par ordinateur, utilisée dans de nombreuses industries, de la surveillance à la santé.",
        url: "https://www.axopen.com/blog/2019/09/open-cv-cest-quoi/"
      },

    ],
    "Prediction": [
      {
        imageSrc:
          "https://images.ctfassets.net/y2ske730sjqp/5QQ9SVIdc1tmkqrtFnG9U1/de758bba0f65dcc1c6bc1f31f161003d/BrandAssets_Logos_02-NSymbol.jpg?w=500&q=80",
        title: "Netflix",
        content: "Utilise des modèles prédictifs pour recommander des films et séries en fonction des préférences des utilisateurs et de leurs comportements passés.",
        url: "https://help.netflix.com/fr/node/100639"
      },
      {
        imageSrc:
          "https://www.1min30.com/wp-content/uploads/2017/12/Embl%C3%A8me-Amazon.jpg?fit=crop&w=500&q=80",
        title: "Amazon",
        content: "Utilise des algorithmes prédictifs pour recommander des produits en fonction des achats et des recherches antérieures.",
        url: "https://aws.amazon.com/fr/personalize/"
      },
      {
        imageSrc:
          "https://static1.anpoimages.com/wordpress/wp-content/uploads/2024/04/spotify-feature-image.jpg?fit=crop&w=500&q=80",
        title: "Spotify",
        content: "Propose des playlists personnalisées (comme \"Discover Weekly\") basées sur les habitudes d'écoute de l'utilisateur.",
        url: "https://www.spotify.com/be-fr/safetyandprivacy/understanding-recommendations"
      },
      {
        imageSrc:
          "https://logos-world.net/wp-content/uploads/2022/05/Google-Ads-Logo.jpg?fit=crop&w=500&q=80",
        title: "Google Ads",
        content: "Utilise des systèmes prédictifs pour afficher des annonces ciblées, basées sur les intérêts et le comportement des utilisateurs.",
        url: "https://support.google.com/google-ads/answer/3448398?hl=fr"
      },
    ],
    "Robotique": [
      {
        imageSrc:
          "https://search.therobotreport.com/wp-content/uploads/2020/07/Boston_dynamics_logo_-e1600705062823.png?fit=crop&w=500&q=80",
        title: "Boston Dynamics (Spot)",
        content: "Robot quadrupède capable de naviguer dans des environnements complexes, utilisé dans des missions de surveillance, d'inspection industrielle et même de secours.",
        url: "https://bostondynamics.com"
      },
      {
        imageSrc:
          "https://m.media-amazon.com/images/I/41WXXqNCABL.png?fit=crop&w=500&q=80",
        title: "Roomba (iRobot)",
        content: "Aspirateur robot autonome capable de naviguer et de nettoyer les sols sans intervention humaine.",
        url: "https://www.irobot.fr/fr_FR/roomba.html"
      },
      {
        imageSrc:
          "https://biz-file.com/c/1612/372801.jpg",
        title: "Waymo (Google)",
        content: "Développe des véhicules autonomes qui utilisent des algorithmes robotiques pour conduire sans intervention humaine.",
        url: "https://waymo.com"
      },
      {
        imageSrc:
          "https://cdn.prod.website-files.com/640842c6188be565d27026e6/640842c6188be5cf64702777_hanson-robotics.svg?fit=crop&w=500&q=80",
        title: "Sophia (Hanson Robotics)",
        content: "Un robot humanoïde sophistiqué qui utilise des algorithmes d'IA pour interagir avec les humains dans un cadre social.",
        url: "https://www.hansonrobotics.com/sophia/"
      },

    ],
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url}  target="_blank" initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc} style={{ backgroundColor: 'white' }} >
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>En savoir plus</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
