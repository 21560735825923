import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Hero2 from "components/hero/TwoColumnWithFeaturesAndTestimonial_jimmin.js";

import MainFeature from "components/features/TwoColWithButton.js";

import TabGrid from "components/cards/TabCardGrid.js";

import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// Image
import mvai from "../images/mvai.jpg";
import iaprojet from "../images/iaprojet.jpg";

//import profil from "../images/profil.png?auto=format&fit=crop&w=634&q=80";

/* OFF Script
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";

import Features from "components/features/ThreeColSimple.js";
import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
*/

export default () => {

  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`
    bg-primary-500 text-gray-100 px-4
    transform -skew-x-12 inline-block
    text-3xl overflow-hidden text-ellipsis
    align-middle
  `;

  /*
   const HighlightedText = tw.span`
    bg-primary-500 text-gray-100 px-4
    transform -skew-x-12 inline-block
    text-3xl whitespace-nowrap overflow-hidden text-ellipsis
    align-middle
  `;

  const HighlightedTextInverse = tw.span`
    bg-gray-100 text-primary-500 px-4
    transform -skew-x-12 inline-block
    text-3xl whitespace-nowrap overflow-hidden text-ellipsis
    align-middle
  `;
  */

  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  const TestimonialQuote2 = () => (
    <>
      Développeur indépendant de jeux vidéo depuis 10 ans.<br />
      Et actuellement en formation IA au Greta, je suis à la recherche d'une alternance.<br />
      <br />
      Contactez-moi pour discuter de vos projets !
      <br />
      <br />
      Disponibilité : Maintenant !
    </>
  );

  // Header Style
  //const Header = tw.h1`text-3xl sm:text-4xl font-bold text-center tracking-tight leading-tight`;

  return (
    <AnimationRevealPage>
          <Hero2 
      heading={<>Besoin d'un Développeur (IA)?<br/><HighlightedText>Recrutez-moi !</HighlightedText></>}
      description={<>
      <b>Développeur IA spécialisé dans l'intégration et l'optimisation de solutions intelligentes.</b> <br />
      Expert en ajustement de modèles, développement d'applications IA, et déploiement cloud sécurisé. <br />
      J'accompagne vos projets IA de la conception à la mise en production pour des performances maximales.<br />
      </>}
      imageSrc={
        // profil
        // "https://img.freepik.com/premium-photo/confident-handsome-successful-asian-man-smiling-looking-determined_911620-15404.jpg"
        "https://gifdb.com/images/high/excited-chinese-man-jazz-hands-howyxzrvm16btt9y.gif"
      }
      imageDecoratorBlob={true}
      primaryButtonUrl="https://jimmindev.github.io/linkme/"
      primaryButtonText="- Mon LinkTree -"
      buttonRounded={true}
      features={[
        "Intelligence Artificielle", 
        "Développement d'applications Python et Web",
        "Gestion de Bases de Données", 
      ]}
      testimonial={{
        quote: <TestimonialQuote2 /> ,
        customerName: "Jimmy Inthalangsy",
        customerCompany: "ia-besancon"
      }}

    />

      <MainFeature
        subheading={<Subheading>Depuis 2023</Subheading>}
        heading={
          <>
            Nous exploitons l'IA pour réinventer
            <wbr /> <HighlightedText>les solutions technologiques.</HighlightedText>
          </>
        }
        description={
          <Description>
            Explorez comment nos projets en IA répondent aux défis modernes et apportent des solutions innovantes.
            <br />
            <br />
            L'IA transforme les industries et améliore les processus grâce à des algorithmes intelligents et des analyses avancées.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Voir Nos Projets"
        imageSrc={
          iaprojet
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />



      <TabGrid
        heading={
          <>
            Les entreprises qui utilisent <HighlightedText>l'IA.</HighlightedText>
          </>

        }
      />
      { /* 
      <Features
        heading={
          <>
            Services <HighlightedText>Innovants.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "Analyse Prédictive",
            description: "Des solutions pour anticiper les tendances et optimiser les décisions.",
            url: "https://google.com"
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Automatisation Intelligente",
            description: "Automatisez les tâches répétitives avec des technologies avancées.",
            url: "https://timerse.com"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Personnalisation Avancée",
            description: "Créez des expériences personnalisées grâce à l'IA.",
            url: "https://reddit.com"
          }
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />

      */}


      <Hero
        heading={<>Transformez votre vie avec<HighlightedText>l'Intelligence Artificielle.</HighlightedText></>}
        description="L'IA simplifie vos tâches et personnalise vos expériences grâce à des technologies avancées. Découvrez comment elle peut améliorer votre quotidien et stimuler l'innovation."
        imageSrc={mvai}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="En savoir plus"
        primaryButtonUrl="https://bigmedia.bpifrance.fr/news/intelligence-artificielle-generative-de-quoi-parle-t"
        watchVideoButtonText="Video crée par IA"
      />

<MainFeature
        subheading={<Subheading>Une Expertise Reconnue</Subheading>}
        heading={<>Former vous à l'IA :<HighlightedText>Choisissez le GRETA !</HighlightedText></>}
        description={
          <Description>
            L'intelligence artificielle (IA) est devenue un domaine crucial dans le monde professionnel d'aujourd'hui.<br/>
            Pour se préparer à cette révolution technologique, il est essentiel de choisir une formation adaptée.<br/>
            Les GRETA, groupes d'établissements publics de formation pour adultes, offrent des formations spécialisées dans ce domaine en pleine expansion.
          </Description>
        }
        buttonRounded={true}
        textOnLeft={false}
        primaryButtonText="- La Formation -"
        primaryButtonUrl="https://greta-franche-comte.fr/formation/developpeur-intelligence-artificielle/"
        imageSrc="https://greta-franche-comte.fr/wp-content/uploads/2020/07/Informatique-Réseau-800p.jpg?auto=format&fit=crop&w=768&q=80"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />

   {/*   
      <MainFeature2
        subheading={<Subheading>Une Expertise Reconnue</Subheading>}
        heading={<>Pourquoi <HighlightedText>Choisir l'IA ?</HighlightedText></>}
        statistics={[
          {
            key: "Projets",
            value: "50+",
          },
          {
            key: "Clients",
            value: "30+"
          },
          {
            key: "Solutions",
            value: "100+"
          }
        ]}
        primaryButtonText="Explorer Nos Projets"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
*/}
        {/* 
      <Testimonial
        subheading=""
        heading={<>Les Clients <HighlightedText>Apprécient l'IA.</HighlightedText></>}
      />
      <DownloadApp
        text={<>Découvrez comment l'<HighlightedTextInverse>IA</HighlightedTextInverse> transforme votre quotidien grâce à notre expertise.</>}
      />
        */}


<TeamCardGrid 
  heading = "Nous sommes en recherche d'alternance"
  description = {<>
  Disponibles pour collaborer sur vos projets, nous pouvons vous aider à intégrer l'IA dans vos solutions. 
  <br/><br/>Contactez-nous pour en discuter !
  </>}
  
        subheading={<Subheading>Collaborons ensemble</Subheading>}
      />

    </AnimationRevealPage>
  );
}
