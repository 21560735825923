import React from "react";
import styled from "styled-components"; // Import styled-components
import GlobalStyles from 'styles/GlobalStyles';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RestaurantLandingPage from "demos/RestaurantLandingPage.js";


import Error404 from "pages/Error404.js";

import About from "pageson/AboutUs.js";
import ContactUs from "pageson/ContactUs.js";



import Header from "components//headers/light.js";
import Footer from "components/footers/FiveColumnDark.js";

// Styles globals pour garantir que le pied de page reste en bas
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
`;

function App() {
  return (
    <AppContainer>
      <GlobalStyles />
      <Router>
      
      <Header />
        <MainContent>
          <Routes>

            <Route path="/about" element={<About/>} />

            <Route path="/projets" element={<Error404/>} />

            <Route path="/contact" element={<ContactUs/>} />
            <Route path="/rgpd" element={<Error404/>} />
            <Route path="/politique" element={<Error404/>} />
            <Route path="/conditions" element={<Error404/>} />
            <Route path="/avertissement" element={<Error404/>} />


            <Route path="/" element={<RestaurantLandingPage/>} />
          </Routes>
        </MainContent>
        <Footer />
      </Router>
    </AppContainer>
  );
}

export default App;
