import React from "react";
import { Container } from "components/misc/Layouts";
import tw from "twin.macro";

import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex justify-center`; // Centre le texte horizontalement
const Heading = tw(SectionHeading)`text-xl sm:text-2xl font-black tracking-wide text-center`;

export default ({ headingText = "! Page en construction !" }) => {
  return (
    <Container>
      <HeadingRow>
        <Heading><br/><br/><br/><br/>{headingText}</Heading>
      </HeadingRow>
    </Container>
  );
};
