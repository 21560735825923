import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";

// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <MainFeature1
        subheading={<Subheading>L'équipe en formation IA à Besançon</Subheading>}
        heading="Qui sommes-nous ?"
        description = {<>
        Nous suivons actuellement une formation au GRETA de Besançon, nous spécialisant dans l'intégration de l'intelligence artificielle (IA). 
        <br/>Notre objectif est de maîtriser ces technologies pour les appliquer dans des projets concrets et innovants.
        </>}
        buttonRounded={false}
        primaryButtonText="En savoir plus"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>L'IA au cœur de nos projets</Subheading>}
        heading="Notre expertise"
        description = {<>
          Nous travaillons sur des projets où l'IA permet d'améliorer les performances et d'ajouter de nouvelles fonctionnalités aux applications.
          <br/>De la conception au développement, notre équipe intègre des solutions d'IA adaptées aux besoins actuels.
          </>}
        buttonRounded={false}
        primaryButtonText="Découvrir nos projets"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      
      <TeamCardGrid 
  heading = "Nous sommes en recherche d'alternance"
  description = {<>
  Disponibles pour collaborer sur vos projets, nous pouvons vous aider à intégrer l'IA dans vos solutions. 
  <br/><br/>Contactez-nous pour en discuter !
  </>}
  
        subheading={<Subheading>Collaborons ensemble</Subheading>}
      />
    </AnimationRevealPage>
  );
};
